<template>
  <div class="tabbar-Line">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
export default {
  name: "bar",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    chartClass: {
      type: String,
      default: "55",
    },
    isFresh: {
      type: [Boolean, Number],
      default() {
        return false | 0;
      },
    },
    chartData: {
      type: [Number, String],
      default() {
        return 0 | "0";
      },
    },
    title: {
      type: String,
      default: "质保期剩余",
    },
    ringColor: {
      type: Array,
      default() {
        return [
          {
            offset: 0,
            color: "#00f2fe", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "#4facfe", // 100% 处的颜色
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    isFresh() {
      this.drawWeatherLine();
    },
    chartData() {
      this.drawWeatherLine();
    },
    ringColor() {
      this.drawWeatherLine();
    },
    title() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表

      var data = {
        value: Number(this.chartData).toFixed(2),
        company: this.title,
        ringColor: this.ringColor,
      };
      console.log(data, "data");
      let option = {
        title: {
          text: data.value + "%" + "\n" + data.company,
          x: "center",
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: "14",
          },
        },
        color: ["#485271"],
        legend: {
          show: false,
          data: [],
        },

        series: [
          {
            name: "Line 1",
            type: "pie",
            clockWise: true,
            radius: [this.chartClass, "65"], //设置圆环的半径
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: data.value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: data.ringColor,
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "",
                value: 100 - data.value,
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabbar-Line{
  width: 100%;
  height: 100% !important;
  //position: absolute;
  //top:-20px
  }

  .ringChartClass{

  height: 200px !important;
  }
</style>
