<template>
  <div class="centerContent">
    <div class="gmv-title">{{ title }}</div>
    <!-- 数字滚动 -->
    <div class="chartNum">
      <div class="box-item">
        <li
          :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
          v-for="(item, index) in orderNum"
          :key="index"
        >
          <span v-if="formatNumber(item)">
            <i ref="numberItem">0123456789</i>
          </span>
          <span class="comma" v-else>{{ item }}</span>
        </li>
      </div>
      <span class="centerLeftMoney">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countEnterNum: {
      type: [String, Number],
      default() {
        return "0" | 0;
      },
    },
    title: {
      type: String,
      default: "系统累计运行天数",
    },
    unit: {
      type: String,
      default: "天",
    },
  },
  data() {
    return {
      orderNum: [], // 默认
    };
  },
  components: {},
  created() {},
  mounted() {
    // console.log(this.countEnterNum, this.orderNum);
    let list = [];
    const NumberList = this.formatMoney(this.countEnterNum).split("");
    console.log(NumberList);
    for (let i = 0; i < NumberList.length; i++) {
      if (NumberList[i] == ",") {
        list.push(NumberList[i]);
      } else {
        list.push("0");
      }
    }
    this.orderNum = list;

    this.getCountEnterNum();
    // this.getCountEnterNum();
  },
  watch: {
    /* eslint-disable */
    countEnterNum: {
      deep: true,
      handler: function (newV, oldV) {
        console.log(newV);
        let list = [];
        const NumberList = this.formatMoney(newV).split("");
        console.log(NumberList);
        for (let i = 0; i < NumberList.length; i++) {
          if (NumberList[i] == ",") {
            list.push(NumberList[i]);
          } else {
            list.push("0");
          }
        }
        this.orderNum = list;

        this.getCountEnterNum();
      },
    },
    /* eslint-enable */
  },
  methods: {
    formatMoney(num) {
      /* eslint-disable */
      var res = num.toString().replace(/\d+/, function (n) {
        // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
          return $1 + ",";
        });
      });
      return res;
      /* eslint-enable */
    },
    // 判断是否是数值
    formatNumber(val) {
      /* eslint-disable */
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(val)) {
        return false;
      } else {
        return true;
      }
      /* eslint-enable */
    },
    getCountEnterNum() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.toOrderNum(this.countEnterNum); // 这里输入数字即可调用
          this.setNumberTransform();
        });
      }, 1000);
    },
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量
      const numberArr = this.orderNum.filter((item) => {
        // console.log(this.formatNumber(item));
        return this.formatNumber(item);
      });
      // console.log(numberItems);
      // 结合CSS 对数字字符进行滚动,显示订单数量
      /* eslint-disable */
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index];
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`;
      }
      /* eslint-enable */
    },
    // 处理总数字
    toOrderNum(num) {
      num = num.toString();
      /* eslint-disable */
      // num = num.slice(0, 2) + ',' + num.slice(2, 5) + ',' + num.slice(5, 8);
      num = this.formatMoney(num);
      /* eslint-enable */
      this.orderNum = num.split(""); // 将其便变成数据，渲染至滚动数组
      //   }
    },
  },
};
</script>

<style lang="scss" scoped>
.centerContent {
  width: 100%;
  display: flex;
  //justify-content: center;
  //margin-left: 40px;
  flex-wrap: wrap;
  .gmv-title {
    width: 100%;
    text-align: left;
    //margin-top: 5vh;
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 10px;
    color: #1ca8d9;
  }
  .chartNum {
    display: flex;
    //justify-content: center;
    //margin-left: 40px;
  }
  .centerLeftMoney {
    font-size: 10px;
    font-weight: 600;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    color: #ffffff;
  }
  .cont {
    margin: 0 0;
  }
}

.box-item {
  position: relative;
  height: 45px;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  list-style: none;
  color: #2d7cff;
  writing-mode: vertical-lr;
  text-orientation: upright;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  //margin: 0 auto;
  /* overflow: hidden; */
}

.mark-item {
  width: 10px;
  height: 60px;
  margin-right: 8px;
  line-height: 10px;
  font-size: 30px;
  position: relative;

  & > span {
    position: absolute;
    width: 100%;
    bottom: 10px;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.number-item {
  width: 32px;
  height: 45px;
  //font-size: 30px;
  list-style: none;
  margin-right: 5px;
  border-radius: 4px;
  //color: #e1fffe;
  color: #2391ff;
  font-size: 26px;
  border: 1px solid #2391ff;
  //background: url("~@/assets/imgs/u75.png") no-repeat;
  //background: #2d7cff;
  background: rgba($color: #2391ff, $alpha: 0.3);
  font-weight: 600;
  & > span {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;

    & > i {
      font-style: normal;
      position: absolute;
      // top: 0px;
      // left: 50%;
      top: 17%;
      left: 43%;
      transform: translate(-50%, 0);
      transition: transform 1s ease-in-out;
      letter-spacing: 10px;
    }
  }
}

.number-item:last-child {
  margin-right: 0;
}

.comma {
  color: #ffffff;
  font-size: 50px;
  font-weight: 600;
}
// @media screen and (max-width: 1200px) {
//   .centerContent {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     .gmv-title {
//       width: 100%;
//       text-align: center;
//       margin-top: 5vh;
//       font-weight: bolder;
//       font-size: 16px;
//       margin-bottom: 1.8vh;
//       color: #ffffff;
//     }
//     .chartNum {
//       display: flex;
//       justify-content: center;
//     }
//     .centerLeftMoney {
//       font-size: 25px;
//       font-weight: 600;
//       margin-right: 5px;
//       display: flex;
//       align-items: flex-end;
//       color: #ffffff;
//     }
//     .cont {
//       margin: 0 0;
//     }
//   }

//   .box-item {
//     position: relative;
//     height: 45px;
//     font-size: 22px;
//     line-height: 20px;
//     text-align: center;
//     list-style: none;
//     color: #2d7cff;
//     writing-mode: vertical-lr;
//     text-orientation: upright;
//     -moz-user-select: none;
//     -webkit-user-select: none;
//     -ms-user-select: none;
//     -khtml-user-select: none;
//     user-select: none;
//     margin: 0 auto;
//     /* overflow: hidden; */
//   }

//   .mark-item {
//     width: 10px;
//     height: 50px;
//     margin-right: 4px;
//     line-height: 5px;
//     font-size: 24px;
//     position: relative;

//     & > span {
//       position: absolute;
//       width: 100%;
//       bottom: 10px;
//       writing-mode: vertical-rl;
//       text-orientation: upright;
//     }
//   }

//   .number-item {
//     width: 70px;
//     height: 90px;
//     font-size: 50px;
//     list-style: none;
//     margin-right: 9px;
//     border-radius: 4px;
//     color: #e1fffe;
//     font-size: 58px;
//     background: url("../assets/imgs/u75.png") no-repeat;
//     // background: #2d7cff;
//     font-weight: 600;
//     & > span {
//       position: relative;
//       display: inline-block;
//       margin-right: 10px;
//       width: 100%;
//       height: 100%;
//       writing-mode: vertical-rl;
//       text-orientation: upright;
//       overflow: hidden;

//       & > i {
//         font-style: normal;
//         position: absolute;
//         // top: 0px;
//         // left: 50%;
//         top: 17%;
//         left: 43%;
//         transform: translate(-50%, 0);
//         transition: transform 1s ease-in-out;
//         letter-spacing: 10px;
//       }
//     }
//   }

//   .number-item:last-child {
//     margin-right: 0;
//   }

//   .comma {
//     color: #ffffff;
//     font-size: 50px;
//     font-weight: 600;
//   }
// }
</style>
